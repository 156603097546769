.download-now-container {
	text-align: center;
	border: 1px solid #e6e7e8;
	border-bottom: 8px solid #fe732e;
	border-radius: 5px;
	padding: 2em 1.5em;
	position: relative;
	top: 0;
	transition: top ease 0.5s;
	width: 18em;
}

.download-now-container:hover {
	top: -10px;
	box-shadow: 0 10px 50px -5px rgb(0 0 0 / 15%);
}

.download-now-container img {
	width: 100%;
}

.download-now-container h3 {
	font-family: "NotoSans Light";
	font-size: 1.1rem;
	padding: 0 5%;
}

.download-now-container button {
	font-size: 0.9em;
	padding: 1em 2.5em;
	font-family: "NotoSans Bold";
	border-radius: 7.5px;
	color: #fff;
	background-color: #fe732e;
	border: none;
	cursor: pointer;
}

@media (max-width: 950px) {
	.download-now-container button {
		width: 100%;
	}
}

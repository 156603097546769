.stepper-progress-bar-container {
	width: 100%;
	display: flex;
	justify-content: center;
	padding-top: 2.5em;
}

.stepper-progress-bar-container ul {
	display: flex;
	list-style: none;
	align-items: center;
	justify-content: space-between;
	width: 45em;
	padding: 0;
}

.stepper-progress-bar-container ul li {
	border-radius: 50%;
	border: 1px solid #a4a5a6;
	width: 2.5em;
	height: 2.5em;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: "NotoSans Bold";
	font-size: 1rem;
}

.stepper-progress-bar-container ul progress {
	height: 0.7em;
	width: 15em;
	color: #a4a5a6;
}

.stepper-progress-bar-container ul .currentCompleted {
	background-color: #3390ff;
	color: #fff;
}

.stepper-progress-bar-container ul img {
	width: 70%;
	height: 50%;
}

@media (max-width: 950px) {
	.stepper-progress-bar-container {
		width: 25em;
		max-width: 100%;
			padding-top: 0em;
	}

	.stepper-progress-bar-container ul li {
		font-size: 0.9rem;
	}

	.stepper-progress-bar-container ul progress {
		width: 20%;
	}
}

.stepper-form-container {
	text-align: center;
	width: 45em;
}

.stepper-form-container h5 {
	font-size: 1rem;
	font-family: "NotoSans Medium";
}

.stepper-form-container form {
	display: flex;
	justify-content: center;
}

.stepper-form-container form fieldset {
	border: none;
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 3em;
	padding: 0;
}

.stepper-form-container form input {
	height: 3.2em;
	width: 20em;
	box-sizing: border-box;
	border: 1px solid #dadada;
	border-radius: 5px;
	padding: 0 2.1em 0 1em;
	font-size: 1rem;
}

.stepper-form-container form input:focus {
	outline: none;
}

.stepper-form-container section {
	position: relative;
}

.stepper-form-container section button {
	width: 10em;
	height: 3.2em;
	font-size: 1rem;
	font-family: "NotoSans Bold";
	border-radius: 7.5px;
	border: none;
	position: absolute;
	cursor: pointer;
}

.stepper-form-container section .first-button {
	color: #fe732e;
	background-color: #fff;
	left: 0;
	border: 1px solid #fe732e;
}

.stepper-form-container section .second-button {
	color: #fff;
	background-color: #fe732e;
	right: 0;
	border: 1px solid #fe732e;
}

.stepper-form-container form .input-error {
	border: 1px solid #f2545b;
	background-color: #fdedee;
	box-shadow: 0 0 0 1px #f2545b;
}

.stepper-form-container form .input-success {
	background-color: #f5f8fa;
}

.stepper-form-container form div {
	position: relative;
}

.stepper-form-container form img {
	position: absolute;
	width: 2em;
	height: 2em;
	right: 0.5em;
	top: 0.5em;
	display: none;
	justify-self: center;
}

.stepper-form-container form .show-icon {
	display: inline;
}

.stepper-form-container form p {
	text-align: left;
	font-size: 0.7rem;
	color: #f2545b;
	display: none;
	position: absolute;
}

.stepper-form-container form .show-error {
	display: block;
}

@media (max-width: 950px) {
	.stepper-form-container {
		width: 25em;
		max-width: 100%;
	}

	.stepper-form-container form fieldset {
		flex-direction: column;
		height: 10em;
	}

	.stepper-form-container form input {
		width: 100%;
		font-size: 1.2rem;
	}

	.stepper-form-container form img {
		top: 0.9em;
	}

	.stepper-form-container form p {
		margin-top: 0.4em;
	}
}

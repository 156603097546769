.App{
    overflow: hidden;
}

@font-face {
    font-family: "DMsans Bold";
    src: url("./assets/fonts/DMSans/DMSans-Bold.ttf");
}

@font-face {
    font-family: "NotoSans Regular";
    src: url("./assets/fonts/NotoSans/NotoSans-Regular.ttf");
}

@font-face {
    font-family: "NotoSans Medium";
    src: url("./assets/fonts/NotoSans/NotoSans-Medium.ttf");
}

@font-face {
    font-family: "NotoSans Bold";
    src: url("./assets/fonts/NotoSans/NotoSans-Bold.ttf");
}

@font-face {
    font-family: "NotoSans Light";
    src: url("./assets/fonts/NotoSans/NotoSans-Light.ttf");
}

@font-face {
    font-family: "NotoSans Thin";
    src: url("./assets/fonts/NotoSans/NotoSans-Thin.ttf");
}

@font-face {
    font-family: "NotoSans Extra Bold";
    src: url("./assets/fonts/NotoSans/NotoSans-ExtraBold.ttf");
}


.home-container {
	padding-top: 6em;
}

.home-container .background-div {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	position: absolute;
	top: 0px;
	z-index: -1;
}

.add-blur {
	z-index: 998 !important;
	backdrop-filter: blur(3px);
}

@media (max-width: 950px) {
	.home-container {
		padding-top: 4em;
	}
}

@media (max-width: 500px) {
	.home-container {
		padding-top: 15vw;
	}
}

.description-container {
	display: flex;
	justify-content: center;
	padding: 2em 2em 2em;
}

.description-title-container {
	width: 33em;
}

.description-title-container h1 {
	font-family: "NotoSans Bold";
	margin-top: 0;
	font-size: 3.6rem;
}

.description-text-container {
	width: 33em;
}

.description-text-container p {
	font-family: "NotoSans Regular";
	font-size: 1.2rem;
}

@media (max-width: 1024px) {
	.description-container {
		flex-direction: column;
		align-items: center;
	}

	.preview-container section {
		width: 100%;
		align-items: center;
	}

	.description-text-container {
		width: 100%;
	}

	.description-title-container h1 {
		text-align: center;
		margin: 0;
	}

	.carousel-container .page-controls {
		bottom: -5%;
	}
}

@media (max-width: 650px) {
	.description-container {
		padding: 2vw 2vw 0vw;
	}

	.description-title-container h1 {
		font-size: 10vw;
	}

	.header-container p {
		font-size: 3.2vw !important;
	}

	.description-text-container p {
		font-size: 3.5vw;
	}
}

.navbar-container {
	width: 100%;
	height: 8em;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	position: fixed;
	z-index: 1;
	background-color: #000000;
}

.download-navbar-container {
	width: 100%;
	height: 8em;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	z-index: 1;
}

.navbar-bg {
	background-color: #00000074;
}

.navbar-container nav {
	width: 66em;
	padding: 0 2em;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.navbar-container nav img {
	height: 5.5em;
}

.download-navbar-container nav {
	width: 66em;
	padding: 0 !important;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.download-navbar-container nav img {
	height: 4em !important;
}

.navbar-container nav button {
	font-family: "DMsans Bold";
	font-size: 1rem;
	font-weight: 600;
	padding: 1em 2.5em;
	border-radius: 7.5px;
	border: none;
	cursor: pointer;
}

.download-navbar-container nav a {
	font-family: "DMsans Bold";
	font-size: 1.5rem;
	font-weight: 600;
	cursor: pointer;
	text-decoration: none;
	color: black;
}

@media (max-width: 500px) {
	.navbar-container,
	.download-navbar-container {
		height: 25vw;
	}

	.navbar-container nav button {
		font-size: 3vw;
	}

	.navbar-container nav,
	.download-navbar-container nav {
		padding: 0 2vw;
	}

	.download-navbar-container nav a {
		font-size: 5vw;
	}

	.navbar-container nav img {
		height: 18vw;
	}

	.download-navbar-container nav img {
		height: 18vw !important;
	}
}

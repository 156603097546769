.stepper-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	width: 100%;
	height: 100vh;
	box-sizing: border-box;
	background-color: #fff;
	z-index: 999;
	padding: 0 2em;
	overflow: hidden;
}

.fillToptoBottom {
	animation: fillToptoBottom 1s 0s forwards;
}

@keyframes fillToptoBottom {
	to {
		top: 0vh;
	}
}

.hideBottomtoTop {
	animation: hideBottomtoTop 1s 0s forwards;
	top:0vh
}

@keyframes hideBottomtoTop {
	to {
		top: -100vh;
	}
}

.stepper-container > img {
	position: relative;
	width: 1.5em;
	height: 1.5em;
	align-self: flex-end;
	top: 2em;
	cursor: pointer;
}

@media (max-width: 650px) {
	.stepper-container {
		padding: 1em 1em 0;
	}

	.stepper-container > img{
		top: 0em
	}
}

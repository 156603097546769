.header-container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5em 2em 8em;
	background: url("../assets/images/header-background.png") no-repeat;
	background-size: 100% 100%;
}

.header-container section {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-start;
	color: #fff;
	width: 33em;
}

.header-container h1 {
	font-size: 2.4rem;
	margin: 0.2em 0;
}

.header-container section h1:nth-of-type(1) {
	font-family: "NotoSans Regular";
}

.header-container section h1:nth-of-type(2) {
	font-family: "NotoSans Bold";
}

.header-container section p {
	font-family: "NotoSans Regular";
	font-size: 1.2rem;
	width: 90%;
	line-height: 1.8em;
	margin: 1.5em 0;
}

.header-container section button {
	font-family: "DMsans Bold";
	font-size: 1rem;
	font-weight: 600;
	padding: 1em 2.5em;
	border-radius: 7.5px;
	border: none;
	color: #fff;
	background-color: #3390ff;
	cursor: pointer;
	box-shadow: 0 0.5em 60px #3390ff;
}

.header-container img {
	width: 33rem;
	height: auto;
}

@media (max-width: 1024px) {
	.header-container section {
		align-items: center;
	}
	.header-container section div {
		width: 85%;
	}

	.header-container section p {
		text-align: center;
	}

	.header-container img {
		display: none;
	}

	.header-container section div button {
		width: 100%;
	}
}

@media (max-width: 650px) {
	.header-container {
		background-size: 30000% 90%;
		padding: 15vw 2vw 15vw;
	}

	.header-container h1 {
		font-size: 7vw;
	}

	.header-container section p {
		font-size: 3.5vw !important;
		width: 100%;
	}

	.header-container section div{
		width: 100%;
	}

	.header-container section button {
		width: 100%;
		font-size: 3vw;
	}
}

.footer-container footer {
	height: 12em;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	background-color: #000000;
}

.footer-container footer section {
	width: 40%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.footer-container footer section a {
	cursor: pointer;
}

.footer-container footer section img {
	width: 3em;
}

.footer-container footer p {
	color: #ffffff;
	font-family: "NotoSans Regular";
	font-size: 1.1rem;
	margin: 0;
}

@media (max-width: 1024px) {
	.footer-container footer section {
		width: 80%;
	}
	.footer-container footer p {
		padding: 0 10%;
		text-align: center;
	}
}

.stepper-title-container {
	text-align: center;
	margin: 0.8em 0;
}

.stepper-title-container h2 {
	font-size: 2.1rem;
	margin: 0.15em 0;
	font-family: "NotoSans Regular";
}

.stepper-title-container h2:nth-of-type(3) {
	font-family: "NotoSans Bold";
}

.stepper-title-container h1 {
	font-size: 3.2rem;
	margin: 0;
	font-family: "NotoSans Extra Bold";
}

.stepper-title-container p {
	font-size: 1.2rem;
	font-family: "NotoSans Medium";
	color: rgb(85, 85, 85);
	margin: 1em 0;
}

@media (max-width: 950px) {
	.stepper-title-container {
		width: 25em;
        max-width: 100%;
	}

	.stepper-title-container h2 {
		font-size: 1.5rem;
		margin: 0.15em 0;
		font-family: "NotoSans Regular";
	}

	.stepper-title-container h1 {
		font-size: 2rem;
	}

	.stepper-title-container p {
		font-size: 1rem;
		margin: 0.8em 0;
	}
}

.preview-container {
	margin: auto;
	padding: 0 2em 2em;
	background-size: 100% 100%;
	max-width: 66em;
}

.preview-container h1 {
	text-align: center;
	font-family: "NotoSans Regular";
	font-size: 2.4rem;
	font-style: normal;
	font-weight: 600;
}

.preview-columns-container {
	display: flex;
	justify-content: center;
}

.preview-columns-container section {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-start;
	width: 33em;
}

.details-container h3 {
	font-family: "NotoSans Regular";
	font-size: 1.3rem;
	font-weight: 600;
	margin-bottom: 0;
}

.details-container ul {
	list-style-type: none;
	padding-left: 0;
}

.details-container ul li {
	display: flex;
}

.details-container ul li img {
	width: fit-content;
	height: fit-content;
	align-self: center;
}

.details-container ul li p {
	font-family: "NotoSans Regular";
	display: inline;
	font-style: normal;
	font-weight: 400;
	padding-left: 1.3em;
	font-size: 1.3em;
}

.carousel-container {
	width: 50%;
	position: relative;
}

.carousel-container .page-controls {
	position: absolute;
	bottom: 30%;
	left: 50%;
	background: white;
	opacity: 0;
	transform: translateX(-50%);
	transition: opacity ease-in-out 0.2s;
	box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
	border-radius: 4px;
	font-size: 1rem;
	cursor: pointer;
}

.carousel-container:hover .page-controls {
	opacity: 1;
}

.page-controls button:first-child {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.page-controls button:last-child {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.page-controls button {
	width: 3em;
	height: 3em;
	background: white;
	border: 0;
	font: inherit;
	font-size: 1rem;
	border-radius: 4px;
}

.page-controls span {
	font: inherit;
	font-size: 0.8rem;
	padding: 0 0.5em;
}

.react-pdf__Page__canvas {
	margin: 0 auto;
	width: 100% !important;
	height: 100% !important;
}

@media (max-width: 1024px) {
	.preview-columns-container {
		flex-direction: column;
	}

	.preview-columns-container section {
		width: 100%;
		align-items: center;
	}

	.preview-container h1 {
		text-align: center;
		margin: 0;
	}

	.carousel-container .page-controls {
		bottom: -5%;
	}
}

@media (max-width: 650px) {
	
	.preview-container {
		padding: 0 2vw 3vw;
	}

	.preview-container h1 {
		font-size: 7vw;
		text-align: center;
	}

	.details-container h3 {
		font-size: 3.5vw;
	}

	.details-container ul li p {
		padding-left: 3vw;
		font-size: 3.7vw;
	}
}

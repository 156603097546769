.callToAction-container {
	display: flex;
	justify-content: center;
	padding: 4em 2em 2em;
	background: url("../assets/images/cta-background.png") no-repeat;
	background-size: 100% 120%;
	color: #ffffff;
}

.callToAction-text-container {
	width: 33em;
}

.callToAction-text-container h1 {
	font-family: "NotoSans Regular";
	font-size: 2.4rem;
	font-weight: 600;
	margin: 0;
}

.callToAction-text-container p {
	font-family: "NotoSans Regular";
	font-size: 1.2rem;
}

.callToAction-button-container {
	width: 33em;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.callToAction-button-container button {
	font-family: "DMsans Bold";
	font-size: 1rem;
	font-weight: 600;
	padding: 1em 2.5em;
	border-radius: 7.5px;
	border: none;
	color: #fff;
	background-color: #3390ff;
	cursor: pointer;
	box-shadow: 0 0.5em 60px #3390ff;
}

@media (max-width: 1024px) {
	.callToAction-container {
		flex-direction: column;
		align-items: center;
	}

	.callToAction-text-container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.callToAction-text-container h1 {
		text-align: center;
		width: 90%;
	}

	.callToAction-text-container p {
		width: 90%;
		margin: 1.5em 0;
		text-align: center;
	}

	.callToAction-button-container {
		justify-content: center;
	}

	.callToAction-button-container div {
		width: 85%;
	}

	.callToAction-button-container div button {
		width: 100%;
	}
}

@media (max-width: 650px) {
	.callToAction-container {
		padding: 10vw 2vw 4vw;
		background-size: 30000% 120%;
	}

	.callToAction-text-container{
		width: 100%;
	}

	.callToAction-text-container h1 {
		font-size: 7vw;
		width: 100%;
	}

	.callToAction-text-container p {
		font-size: 3.5vw;
		width: 100%;
	}

	.callToAction-button-container{
		width: 100%;
	}

	.callToAction-button-container div {
		width: 100%;
	}

	.callToAction-button-container div button {
		width: 100%;
		font-size: 3vw;
	}
}
